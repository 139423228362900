<template>
  <div class="recruit">
    <div class="content ">
      <h2>{{ detalList.companyName }}</h2>
      <img :src="detalList.picUrl" alt="" class="banner" />
    </div>
    <div class="fixed">
      <h3><img src="~/static/center/wechat.png" alt="" />扫码分享</h3>
      <div id="qrcode" ref="qrcode"></div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  name: "recruitCompany",
  data() {
    return {
      url: "https://www.cdpee.org.cn/public/recruit/activeDetail.html?id=",
      detalList: [],
    };
  },
  methods: {
    // 生成二维码
    qrCode() {
      console.log(this.url);
      this.$nextTick(function() {
        document.getElementById("qrcode").innerHTML = "";
        let qrcode = new QRCode("qrcode", {
          width: 79 * 5,
          height: 79 * 5,
          text: this.url, // 二维码内容 也可以放url
          colorDark: "#000",
          colorLight: "#fff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        qrcode.clear(); // clear the code.
        qrcode.makeCode(this.url+this.$route.query.id); // make another code.
      });
    },
    async listFn() {
      let res = await this.$axios.get(
        "/api/app-jycy-activity/getZhucanZhanInfo",
        { id: this.$route.query.id }
      );
      if (res.data.success) {
        this.detalList = res.data.data;
      } else {
        this.$message.error(res.data.msg);
      }
    },
  },
  created() {
    this.qrCode();
  },
  mounted() {
    // 事件监听滚动条
    this.listFn();
  },
};
</script>

<style scoped lang="less">
.recruit {
  .content {
    width: 1200px;
    margin: 30px auto;
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
    .banner {
      width: 600px;
      margin: 0 auto;
    }
  }
  .fixed {
    position: fixed;
    top: 30%;
    right: 40px;
    background: white;
    padding: 10px;
    border-radius: 6px;
    text-align: center;
    h3 {
      margin-bottom: 10px;
      img {
        width: 18px;
        vertical-align: middle;
        margin-right: 5px;
      }
      font-size: 14px;
      font-weight: 500;
    }
    #qrcode {
      margin: 0 auto;
    }
  }
}
#qrcode {
  display: block;
  margin-top: 10px;
  width: 79px;
  height: 79px;
  /deep/canvas {
    width: 300px;
    height: 300px;
  }
  /deep/ img {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
